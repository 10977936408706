import React from "react"
import styled from "styled-components"

import Container from "./Container"
import { GRAY, DARK_GRAY } from "../constants/colors"
import Image from "./Image"

const Wrapper = styled.div`
  padding: 30px 15px;
  background: ${GRAY};
`

const Header = styled.h3`
  font-size: 20px;
  margin-bottom: 30px;
  text-align: center;
`

const DescriptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

const Paragraph = styled.p`
  margin-bottom: 16px;
  line-height: 1.5;
`

const SummaryWrapper = styled.div`
  background: ${DARK_GRAY};
  color: white;
  padding: 16px;
  font-size: 14px;
  line-height: 1.5;

  a {
    color: white;
  }
`

const Footer = () => (
  <footer>
    <Wrapper>
      <Container>
        <div>
          <Header> Restauracja bon apetyt</Header>
          <DescriptionWrapper>
            <div>
              <Paragraph>
                Prowadzimy firmę gastronomiczną od 2001 roku. W swojej ofercie
                mamy menu codzienne, gorące półmiski i catering. Znajdą nas
                Państwo w Dąbrowie Chełmińskiej przy ul. Sportowa 8. Na terenie
                Bydgoszczy i okolic oferujemy dostawę posiłków.
              </Paragraph>
              <Paragraph>Gorąco zapraszamy i życzymy smacznego!</Paragraph>
            </div>
            <Image src="restauracja.JPG" />
          </DescriptionWrapper>
        </div>
      </Container>
    </Wrapper>
    <SummaryWrapper>
      <Container>
        restauracja bon apetyt
        <br />
        Dąbrowa Chełmińska ul. Sportowa 8
        <br />
        DOSTAWA tel. <a href="tel:662000633">662 000 633</a>
        <br />
        facebook{" "}
        <a
          target="_blank"
          href="https://www.facebook.com/restauracja.bon.appetit"
        >
          BON Apetyt
        </a>
        <br />
        <a target="_blank" href="https://bonmenu.pl">
          www.bonmenu.pl
        </a>
        <br />
        <a target="_blank" href="https://goracepolmiski.pl">
          www.goracepolmiski.pl
        </a>
        <br />
        Gorąco zapraszamy i życzymy smacznego.
      </Container>
    </SummaryWrapper>
  </footer>
)

export default Footer
